import React from 'react';
import PropTypes from 'prop-types';
import styled  from 'styled-components';
import { Button as Link } from 'reactstrap';
import Left from 'images/left.inline.svg';
import Close from 'images/close.inline.svg';

import Button from 'components/Common/Form/Button';

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;  
`;

const StyledLink = styled(Link)`
  ${props => props.theme.main.fonts.body.normal};
  color: ${props => props.theme.main.colors.secondary.dark1};
  position: relative;
  margin-top: 29px;
  padding-top: 0;
  padding-bottom: 0;
  span {
    padding-left: 11px;
  }
  svg {
    position: absolute;
    left: 0;
    margin-top: 5px;
    path {
      fill: ${props => props.theme.main.colors.secondary.dark1};
    }
  }
  &:hover, &:active, &:focus {
    color: ${props => props.theme.main.colors.secondary.dark1};    
    text-decoration: underline;
    svg {
      path {
        fill: ${props => props.theme.main.colors.secondary.dark1};
      }
    }
  }
`;

const Navigation = ({buttonLabel, disabled = false, onClickForward, onClickBack = null, backLabel = null, className = null, children, displayBackIcon = false, displayCloseIcon = false}) => {
  return (
    <NavigationContainer className={className}>
      <Button type="submit" onClick={onClickForward} disabled={disabled} name="button-submit">{buttonLabel}</Button>
      {onClickBack !== null ? <StyledLink color="link" onClick={onClickBack} data-testid="button-back">{!backLabel ? <span><Left /> Back</span> : <span>{displayBackIcon ? <Left /> : displayCloseIcon ? <Close /> : null} {backLabel}</span>}</StyledLink> : null}
      {children}
    </NavigationContainer>);
}

Navigation.propTypes = {
  buttonLabel: PropTypes.string,
  disabled: PropTypes.bool,
  onClickForward: PropTypes.func,
  onClickBack: PropTypes.func,
  className: PropTypes.string,
  backLabel: PropTypes.string,
  children: PropTypes.node,
  displayBackIcon: PropTypes.bool,
  displayCloseIcon: PropTypes.bool
}

export default Navigation;