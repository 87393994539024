import React from 'react';
import PropTypes from 'prop-types';
import styled  from 'styled-components';
import { Label as BootstrapLabel } from 'reactstrap';

const StyledLabel = styled(BootstrapLabel)`
  ${props => props.theme.main.fonts.application.h3};
  color: black;

  span {
    ${props => props.theme.main.fonts.application.micro};
    color: ${props => props.theme.main.colors.grey.base};
  }

  &.form-check-label {
    ${props => props.theme.main.fonts.body.normal};
  }
`;


const Input = ({children, check=false, name}) => (
  <StyledLabel for={name} check={check}>{children}</StyledLabel>
);

Input.propTypes = {
  children: PropTypes.node.isRequired,
  check: PropTypes.bool,
  name: PropTypes.string
}

export default Input;