import React from 'react'

import Layout from 'components/Layout/Layout';
import Employment from 'components/Employment/Employment';
import SEO from 'components/seo';

const EmploymentPage = () => (
  <Layout>
    <SEO title="Employment" />
    <Employment />
  </Layout>
)

export default EmploymentPage
