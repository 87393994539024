import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Input as BootstrapInput, InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap';
import Dropdown from 'images/dropdown.svg';
import DropdownGrey from 'images/dropdown.grey.svg';

const StyledInput = styled(BootstrapInput)`
  ${props => props.theme.main.fonts.body.normal};
  background-color: white;
  border: 2px solid ${props => props.theme.main.colors.neutral.line};
  border-radius:0;
  height: 48px;
  color: black;

  &:disabled {
    background-color: ${props => props.theme.main.colors.neutral.offwhite};
  }
  &:focus, &:active {
    box-shadow: none;
    border: 2px solid ${props => props.theme.main.colors.secondary.base};
  }

  option[value=""][disabled] {
    display: none;
  }

  ${props => props.value === '' && css`
    color: ${props => props.theme.main.colors.grey.base};
  `}
`;

const InputContainer = styled.div`
  select {
    appearance:none;
    background: url(${Dropdown}) no-repeat right white;
    background-position-x: calc(100% - 16px);

    &:disabled {
      background-image: url(${DropdownGrey});
      background-position-x: calc(100% - 23px);
    }

    &::-ms-expand {
      display: none;
    }
  }
`

const StyledInputGroupText = styled(InputGroupText)`
  ${props => props.theme.main.fonts.body.normal};
  background-color: ${props => props.theme.main.colors.neutral.offwhite};
  border-radius:0;
  border: 2px solid ${props => props.theme.main.colors.neutral.line};
  height: 48px;
  padding: 0 30px;
`;

const StyledInputGroupAddon = styled(InputGroupAddon)`
  margin-left: -2px;
`;


const Input = ({type, name, value, children, onChange, onBlur, disabled = false, addOnText = null, maxLength = null}) => (
  addOnText !== null ?
  <InputGroup>
    <StyledInput id={name} type={type} name={name} value={value} onChange={onChange} onBlur={onBlur} disabled={disabled} maxLength={maxLength}>{children}</StyledInput>
    <StyledInputGroupAddon addonType="append">
      <StyledInputGroupText>{addOnText}</StyledInputGroupText>
    </StyledInputGroupAddon>
  </InputGroup> 
  : <InputContainer>
      <StyledInput id={name} type={type} name={name} value={value} onChange={onChange} onBlur={onBlur} disabled={disabled} maxLength={maxLength}>{children}</StyledInput>
    </InputContainer>
);

Input.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  addOnText: PropTypes.string,
  maxLength: PropTypes.string
}

export default Input;