import React from 'react';
import PropTypes from 'prop-types';
import styled  from 'styled-components';

const StyledForm = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;

  padding-bottom: 48px;

  .form-group {
    p {
      ${props => props.theme.main.fonts.body.normal};
      color: ${props => props.theme.main.colors.grey.base};
      margin-bottom: 8px;
    }
  }
`;

const Form = ({children, className = null}) => (
  <StyledForm className={className}>{children}</StyledForm>
);

Form.propTypes = {
  children: PropTypes.node.isRequired
}

export default Form;